@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftBlack.otf') format('opentype');
    font-weight: normal;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftBold.otf') format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftBlackItalic.otf') format('opentype');
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftBoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftExtraBold.otf') format('opentype');
    font-weight: 800;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftExtraBoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftHeavy.otf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftHeavyItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftLight.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftLightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftMedium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftMediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftRegular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftRegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftSemiBold.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftSemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftThin.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftUltraLight.otf') format('opentype');
    font-weight: 200;
}

@font-face {
    font-family: "Qanelas Soft";
    src: url('./assets/fonts/qanelas-soft/QanelasSoftUltraLightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}
